import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const RowCenter = styled(Row)`
  justify-content: center;
`

export const Col = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
`

export const ColCenter = styled(Col)`
  align-items: center;
`

export const CenterWrapper = styled(ColCenter)`
  position: relative;
  width: calc(100% - 50px);
  justify-content: center;
  padding: 0 25px;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    width: calc(100% - 80px);
    padding: 20px 40px;
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 160px);
    padding: 20px 80px;
  }
  @media screen and (min-width: 1280px) {
    width: calc(100% - 360px);
    padding: 80px 180px;
  }
`

export const Wrapper = styled(CenterWrapper)`
  align-items: flex-start;
`
export const PageWrapper = styled(Wrapper)`
  padding: 80px 25px 0;
  @media screen and (min-width: 768px) {
    padding: 80px 40px;
  }
  @media screen and (min-width: 1024px) {
    padding: 100px 80px;
  }
  @media screen and (min-width: 1280px) {
    padding: 100px 180px;
  }
`

export const SubTitle = styled.h2`
  font-size: 1.6rem;
  font-family: "Montserrat";
  font-weight: 800;
  color: #4b8fe2;
  margin-top: 60px;
  @media screen and (min-width: 768px) {
    margin-top: 80px;
  }
`

export const TitleSpan = styled.span`
  font-size: 2.4rem;
  font-weight: 600;
  @media screen and (min-width: 768px) {
    font-size: 3.2rem;
  }
`

export const Text = styled.p`
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    margin-top: 40px;
  }
`
