import styled from "styled-components"
import { ColCenter, Col } from "../main"

export const Wrapper = styled.div`
  margin-top: 100px;
`

export const Contact = styled(ColCenter)`
  padding: 40px 25px;
  background-color: #4b8fe2;
  @media screen and (min-width: 768px) {
    padding: 40px;
  }
  @media screen and (min-width: 1024px) {
    padding: 40px 80px;
  }
  @media screen and (min-width: 1280px) {
    padding: 40px 180px;
  }
`

export const ContactButton = styled(ColCenter)`
  width: calc(100% - 40px);
  background-color: white;
  padding: 15px 20px 20px;
  border-radius: 45px;
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    border-radius: 50px;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
  }
`

export const ButtonCol = styled(ColCenter)`
  @media screen and (min-width: 1024px) {
    transition: all 0.4s;
    transform: ${props => props.hover && "scale(0)"};
  }
`

export const ButtonTitle = styled.span`
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 2.4rem;
  color: #4b8fe2;
`

export const ButtonText = styled.span`
  color: #4b8fe2;
  font-weight: 600;
  margin-top: -3px;
`

export const Span = styled.span`
  color: white;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 3px;
`

export const MinSpan = styled.span`
  color: white;
  font-size: 1.4rem;
`

export const Menu = styled(Col)`
  padding: 40px 25px;
  background-color: #222;
  @media screen and (min-width: 768px) {
    padding: 40px;
  }
  @media screen and (min-width: 1024px) {
    padding: 40px 80px;
  }
  @media screen and (min-width: 1280px) {
    padding: 40px 180px;
  }
`

export const Ul = styled.ul`
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px 0 30px;
`

export const Li = styled.li`
  font-size: 2rem;
  font-family: "Montserrat";
  color: white;
  font-weight: 600;
  padding: 10px 0;
  transition: all 0.4s;
  :hover {
    letter-spacing: 5px;
  }
`

export const FooterText = styled.p`
  width: 100%;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
`
