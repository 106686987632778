import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { Button, Span, Wrapper, Ul, Li } from "./style"
import { Link } from "gatsby"

const Header = () => {
  const [active, setState] = useState(false)
  return (
    <>
      <Button onClick={() => setState(!active)} active={active}>
        <Span active={active} />
        <Span active={active} />
        <Span active={active} />
      </Button>
      <Wrapper active={active}>
        <Ul>
          <Fade bottom delay={400}>
            <Link to="/">
              <Li>Top</Li>
            </Link>
          </Fade>
          <Fade bottom delay={600}>
            <Link to="/service">
              <Li>Service</Li>
            </Link>
          </Fade>
          <Fade bottom delay={800}>
            <Link to="/company">
              <Li>Company info</Li>
            </Link>
          </Fade>
          <Fade bottom delay={1000}>
            <Link to="/contact">
              <Li>Contact us</Li>
            </Link>
          </Fade>
        </Ul>
      </Wrapper>
    </>
  )
}
export default Header
