import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Wrapper,
  Contact,
  ContactButton,
  ButtonCol,
  ButtonTitle,
  ButtonText,
  Span,
  MinSpan,
  Menu,
  Ul,
  Li,
  FooterText,
} from "./style"
import { ChatIcon } from "../../images"
import Logo from "../../images/logo.png"

const Footer = () => {
  const [hover, setState] = useState(false)
  return (
    <Wrapper>
      <Contact>
        <Link to="/contact" style={{ width: "100%" }}>
          <ContactButton
            onMouseOver={() => setState(!hover)}
            onMouseLeave={() => setState(!hover)}
          >
            <ChatIcon hover={hover} />
            <ButtonCol hover={hover}>
              <ButtonTitle>Contact Us</ButtonTitle>
              <ButtonText>お気軽にお問い合わせください</ButtonText>
            </ButtonCol>
          </ContactButton>
        </Link>
        <Span>株式会社 Lyact | リアクト</Span>
        <MinSpan>福岡県福岡市城南区東油山1-35-6-102</MinSpan>
        <MinSpan>080-7513-4348</MinSpan>
      </Contact>
      <Menu>
        <Link to="/">
          <img src={Logo} alt="Lyact Inc Logo" width={90} />
        </Link>
        <Ul>
          <Link to="/">
            <Li>Top</Li>
          </Link>
          <Link to="/service">
            <Li>Service</Li>
          </Link>
          <Link to="/company">
            <Li>Company info</Li>
          </Link>
          <Link to="/contact">
            <Li>Contact us</Li>
          </Link>
        </Ul>
        <FooterText>Copyright © All rights reserved.</FooterText>
      </Menu>
    </Wrapper>
  )
}
export default Footer
