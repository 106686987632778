import styled from "styled-components"
import Play from "../svgs/play.svg"
import Pause from "../svgs/pause.svg"
import Chat from "../svgs/chat.svg"

export const PlayIcon = styled(Play)`
  position: absolute;
  right: 0;
  bottom: 50px;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.2));
  z-index: 10;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: rotate(360deg) scale(1.3);
  }
  @media screen and (min-width: 768px) {
    right: -20px;
  }
`

export const PauseIcon = styled(Pause)`
  position: absolute;
  right: 0;
  bottom: 50px;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.2));
  z-index: 10;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: rotate(-360deg) scale(1.3);
  }
  @media screen and (min-width: 768px) {
    right: -20px;
  }
`

export const ChatIcon = styled(Chat)`
  position: absolute;
  transform: scale(0);
  transition: all 0.4s;
  @media screen and (min-width: 1024px) {
    transform: ${props => props.hover && "scale(1)"};
  }
`
