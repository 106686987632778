import styled from "styled-components"
import { Col } from "../main"

export const Wrapper = styled(Col)`
  position: fixed;
  top: 0;
  width: calc(100% - 50px);
  height: calc(100vh - 120px);
  padding: 60px 25px;
  background-color: #4c8ee2;
  z-index: 100;
  display: ${props => (props.active ? "auto" : "none")};
  transition: all 0.4s;
  @media screen and (min-width: 768px) {
    width: calc(100% - 160px);
    padding: 80px;
  }
`
export const Ul = styled.ul``

export const Li = styled.li`
  font-family: "Montserrat";
  font-size: 3.2rem;
  padding: 10px 0;
  font-weight: 900;
  color: white;
  transition: all 0.4s;
  :hover {
    letter-spacing: 5px;
  }
`
export const Button = styled.div`
  position: fixed;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 15px;
  border-radius: 50%;
  background-color: ${props => (props.active ? "white" : "#222")};
  cursor: pointer;
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  z-index: 120;
  :hover {
    transform: rotate(360deg) scale(1.3);
  }
  @media screen and (min-width: 768px) {
    top: 25px;
    right: 20px;
  }
`

export const Span = styled.span`
  position: absolute;
  left: 15px;
  width: 20px;
  height: 2.5px;
  background-color: ${props => (props.active ? "#4c8ee2" : "white")};
  border-radius: 5px;
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  :nth-of-type(1) {
    top: 16px;
    transform: ${props => props.active && "translateY(8px) rotate(-45deg)"};
  }
  :nth-of-type(2) {
    top: 23px;
    opacity: ${props => (props.active ? 0 : 1)};
  }
  :nth-of-type(3) {
    top: 30px;
    transform: ${props => props.active && "translateY(-6px) rotate(45deg)"};
  }
`
